import { useEffect, useRef } from '@wordpress/element';

const Sliders = (props) => {
	const { attributes, clientId, activeIndex, custom: { carousel = null, setCarousel } } = props;
	const { sliders, options, arrow, indicator } = attributes;

	// Click any content slide quick slide 
	const sliderDom = useRef();
	useEffect(() => {
		carousel?.to(activeIndex || 0);
	}, [activeIndex]);

	// setting autoplay option 
	useEffect(() => {
		if (sliderDom?.current) {
			if (carousel) carousel.dispose();

			var initialize = new bootstrap.Carousel(sliderDom.current, {
				interval: options.interval,
				ride: options.ride === true ? 'carousel' : false,
				pause: options.pause === true ? 'hover' : false
			});
			setCarousel && setCarousel(initialize);
		}
	}, [options]);

	// Return All Slider
	return <div className="bsbCarousel slide carousel" ref={sliderDom}>
		{indicator.visibility && <>
			<div className="carousel-indicators">
				{sliders.map((slider, index) => {
					return <button key={index} type="button" data-bs-target={`#bsbCarousel-${clientId} .carousel`} data-bs-slide-to={index} className={`${index === 0 ? 'active' : ''}`}
						aria-current="true" aria-label="Slide 1"></button>
				})}
			</div>
		</>}

		<div className="carousel-inner">
			{sliders.map((slider, index) => {
				const { img, title, desc } = slider || {};

				return <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
					{img?.url && <img src={img.url} className="d-block w-100" alt={img?.alt || img?.title} />}

					<div className="carousel-caption bsbContentMiddle">
						<h5>{title}</h5>
						<p>{desc}</p>
					</div>
				</div>
			})}
		</div>

		{arrow.visibility && <>
			<div className='bsbButtonDesign'>
				<button className="carousel-control-prev" type="button" data-bs-target={`#bsbCarousel-${clientId} .carousel`} data-bs-slide="prev" aria-label='Carousel left arrow'>
					<svg xmlns="http://www.w3.org/2000/svg" height={arrow.size} width={arrow.size} viewBox='0 0 48 48'><path d="m33 44l-20-20 20-20 2.8 2.8-17.2 17.2 17.2 17.1z" /></svg>

					<span className="visually-hidden">Previous</span>
				</button>

				<button className="carousel-control-next" type="button" data-bs-target={`#bsbCarousel-${clientId} .carousel`} data-bs-slide="next" aria-label='Carousel right arrow'>
					<svg xmlns="http://www.w3.org/2000/svg" height={arrow.size} width={arrow.size} viewBox='0 0 48 48'><path d="m15.2 43.9-2.8-2.85L29.55 23.9 12.4 6.75l2.8-2.85 20 20Z" /></svg>

					<span className="visually-hidden">Next</span>
				</button>
			</div>
		</>}
	</div>
}
export default Sliders;