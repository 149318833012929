import { getBoxValue } from './Const/functions';

const Style = ({ attributes, clientId }) => {

	const { titleTypo, titleColor, descTypo, descColor, titleMargin, descMargin, arrow, indicator, SliderOverly, height, borderRadius } = attributes;

	return <style dangerouslySetInnerHTML={{
		__html: `
		#bsbCarousel-${clientId} h5{
			font-size: ${titleTypo?.fontSize}px;
			color: ${titleColor};
			font-weight: ${titleTypo?.fontWeight};
			margin: ${getBoxValue(titleMargin)};
		}
		#bsbCarousel-${clientId} p {
			font-size: ${descTypo?.fontSize}px;
			color: ${descColor};
			font-weight: ${descTypo?.fontWeight};
			margin: ${getBoxValue(descMargin)};
			line-height:${descTypo?.lineHeight};
		} 
		#bsbCarousel-${clientId} .carousel-item {
			height: ${height};
			border-radius: ${getBoxValue(borderRadius)};
    		box-sizing: border-box;
    		overflow: hidden;
		}
		#bsbCarousel-${clientId} svg{
			fill:${arrow.color};
		}
		#bsbCarousel-${clientId} .carousel-item{
			position:relative;
		}
		#bsbCarousel-${clientId} .carousel-item:after{
			content: '';
			width: 100%;
			height: 100%;
			top: 0;
			position: absolute;
			left: 0;
			background: ${SliderOverly};
		}
		#bsbCarousel-${clientId} .carousel-indicators button {
			width: ${indicator?.width} !important;
			height: ${indicator?.height} !important;
			border-radius: ${indicator?.radius};
		}
		#bsbCarousel-${clientId} .carousel-indicators button{
			background-color:${indicator.color}
		}
		#bsbCarousel-${clientId} .carousel-indicators button.active{
			background-color:${indicator.activeColor};
		}
		
		`.replace(/\s+/g, ' ')
	}} />
}
export default Style;