import { render } from '@wordpress/element';

import Sliders from './Sliders';
import Style from './Style';
import './style.scss';

// Sliders 
document.addEventListener('DOMContentLoaded', () => {
	const allSliders = document.querySelectorAll('.wp-block-bsb-slider');
	allSliders.forEach(sliders => {
		const attributes = JSON.parse(sliders.dataset.attributes);

		render(<>
			<Style attributes={attributes} clientId={attributes.cId} />
			<Sliders attributes={attributes} clientId={attributes.cId} custom={{}} />
		</>, sliders);

		sliders?.removeAttribute('data-attributes');
	});
});